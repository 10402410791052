import React, { ReactElement } from "react";
import styles from "./styles.mod.scss";

function Preloader(): ReactElement {
  return (
    <React.Fragment>
      <div className={styles.circle_container} />
      <div className={styles.icon_container}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="35"
          viewBox="0 0 17 35"
        >
          <path
            fill="#FCE33F"
            fillRule="evenodd"
            d="M0 1.877v16.89c0 1.033.826 1.877 1.836 1.877h3.672v13.42c0 .956 1.23 1.294 1.708.469l9.53-16.704c.716-1.257-.166-2.815-1.58-2.815h-4.15l4.573-12.48C16.048 1.314 15.167 0 13.88 0H1.836C.826 0 0 .845 0 1.877z"
          />
        </svg>
      </div>
    </React.Fragment>
  );
}

export default Preloader;
